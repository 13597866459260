<template>
  <div
    :class="
      'col-xl-' +
      prodotto.colonne +
      ' col-md-' +
      prodotto.colonne +
      ' col-sm-12'
    "
    v-if="!errorPolizza"
  >
    <loading :active="isLoading" :is-full-page="false"></loading>
    <card
      :gradient="index % 2 === 0 ? 'info' : 'secondary'"
      class="card-pricing shadow-lg rounded border-0 text-center mb-4 p-3"
      :style="{ minHeight: '96%' }"
      header-classes="bg-transparent"
      :header-styles="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100px',
        backgroundImage: 'url(' + prodotto.urlImage + ')',
      }"
      body-classes="px-lg-7"
      footer-classes="bg-transparent"
    >
      <template v-slot:header>
        <h4
          :class="
            index % 2 === 0
              ? 'text-uppercase ls-1 text-white py-3 mb-0'
              : 'text-uppercase ls-1 text-black py-3 mb-0'
          "
        >
          {{ prodotto.descrizione }}
        </h4>
      </template>
      <div
        class="row"
        v-if="
          polizza &&
          (polizza.status === 10 ||
            polizza.status === 11 ||
            polizza.status === 12 ||
            polizza.status === 98 ||
            polizza.status === 50 ||
            polizza.status === 97 ||
            polizza.status === 99)
        "
      >
        <div class="col-md-12">
          <h2 :class="index % 2 === 0 ? 'text-white' : 'text-black'">
            POLIZZA REDDITO SENZA SOPRESE
            {{ decodePolizzaDesc(polizza.RssPreventivoTestata.tariff_scelta)
            }}<br />
            Numero
            {{ polizza.policy_number }}
          </h2>
          <h4 class="ls-1 text-primary mb-0 mt-2">
            <badge
              rounded
              v-if="statuspolizza"
              :type="decodePolizzaStatusType(statuspolizza.offer_status)"
              size="lg"
              >{{ decodePolizzaStatus(this.statuspolizza.offer_status) }}</badge
            >
          </h4>

          <div
            :class="
              index % 2 === 0 ? 'text-white display-3' : 'text-black display-3'
            "
            v-if="tariffa"
          >
            Premio lordo annuo
            {{ toCurrency(this.tariffa.yearly_gross_premium) }}
          </div>
          <div class="card-description"></div>
          <div class="table-responsive">
            <table
              class="table table-primary text-white table-bordered mt-3"
              v-if="tariffa"
            >
              <tbody>
                <tr>
                  <td>Premio caso morte</td>
                  <td>
                    {{ toCurrency(tariffa.yearly_termlife_gross_premium) }}
                  </td>
                </tr>
                <tr>
                  <td>Premio gravi malattie</td>
                  <td>
                    {{
                      toCurrency(tariffa.yearly_critical_illness_gross_premium)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Premio invalidità</td>
                  <td>
                    {{ toCurrency(tariffa.yearly_invalidity_gross_premium) }}
                  </td>
                </tr>
                <tr>
                  <td>Premio per non autosufficienza</td>
                  <td>
                    {{ toCurrency(tariffa.yearly_ltc_gross_premium) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <span
        v-else
        :class="
          index % 2 === 0 ? 'text-white text-left' : 'text-black text-left'
        "
        v-html="prodotto.descrizioneLunga"
      ></span>
      <template v-if="polizza" v-slot:footer>
        <div class="row">
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              v-if="!polizza.start_underwriting"
              :block="true"
              size="sm"
              @click="goToUploadDocs()"
              type="danger"
              >Carica Esiti visite mediche</base-button
            >
          </div>
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToFabbisogni()"
              >Fabbisogni</base-button
            >
          </div>
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToPortalePagamenti()"
              v-if="prodotto.enablefatt"
              >Portale Pagamenti</base-button
            >
          </div>
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToDocumenti()"
              >Documenti contrattuali</base-button
            >
          </div>
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              size="sm"
              :block="true"
              type="primary"
              @click="goToCoperture()"
              >Coperture</base-button
            >
          </div>
          <div class="col-md-12 col-sm-12 mb-2">
            <base-button
              size="sm"
              :block="true"
              type="primary"
              @click="goToBeneficiari()"
              >Beneficiari</base-button
            >
            <base-button
              :block="true"
              size="sm"
              type="primary"
              @click="goToAgente()"
              >Il tuo Agente</base-button
            >
          </div>
        </div>
      </template>
      <template v-else v-slot:footer>
        <a
          @click="goToAgente"
          style="cursor: pointer"
          :class="index % 2 === 0 ? 'text-white' : 'text-black'"
          >Contattaci</a
        >
      </template>
    </card>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import apiCall from "@/utils/api";
import { formatDate, toCurrency } from "@/filters/formatDate";
import { useRouter } from "vue-router";

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { runToast } from "@/util/notif";
export default {
  name: "RedditoSenzaSorpreseComponent",
  //I am passing the name of the Component as a prop
  props: {
    prodotto: {
      type: Object,
      required: true,
    },
    polizza: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    Loading,
  },
  setup(props) {
    const router = useRouter();

    const isLoading = ref(false);
    const tariffa = ref(null);
    const tariffe = ref(null);
    const statuspolizza = ref(null);
    const errorPolizza = ref(false);

    onMounted(async () => {
      isLoading.value = true;

      if (props.polizza) {
        const ressta = await apiCall({
          url: "/private/polizza/status/" + props.polizza.policy_number,
        });

        if (ressta) {
          statuspolizza.value = ressta;
        }

        if (ressta.err) {
          errorPolizza.value = true;
        }
        for (
          let i = 0;
          i < props.polizza.RssPreventivoTestata.RssPreventivoTariffa.length;
          i++
        ) {
          if (
            props.polizza.RssPreventivoTestata.RssPreventivoTariffa[i]
              .tariff === props.polizza.RssPreventivoTestata.tariff_scelta
          ) {
            tariffa.value =
              props.polizza.RssPreventivoTestata.RssPreventivoTariffa[i];
            tariffe.value =
              props.polizza.RssPreventivoTestata.RssPreventivoTariffa[
                i
              ].RssPreventivoTariffaEvolution;
          }
        }
      }
      isLoading.value = false;
    });

    const openDiagnosi = async function () {
      try {
        let resp = await apiCall({
          url: "/private/polizza/linkdiagnosi",
          method: "GET",
        });
        window.open(resp.url, "_blank");
      } catch (e) {
        runToast("Errore download file", "top-center", "danger");
      }
    };

    const decodeDiagnosiType = function (type) {
      switch (type) {
        case 11:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 22:
          return "Diagnosi Futuro senza sorprese".toUpperCase();
        case 12:
          return "Contributi senza sorprese".toUpperCase();
        case 23:
          return "Contributi senza sorprese".toUpperCase();
      }
    };
    const decodePolizzaDesc = function (desc) {
      switch (desc) {
        case "reddito_senza_sorprese":
          return "COSTANTE";
        case "reddito_senza_sorprese_decrescente":
          return "DECRESCENTE";
      }
    };
    const decodePolizzaStatus = function (status) {
      switch (status) {
        case "finished":
          return "EMESSA";
        case "broken rules":
          return "IN ERRORE";
        case "awaiting UW docs":
          return "IN ATTESA DEI DOCUMENTI CLINICI";
        case "awaiting SQL UW":
          return "IN VALIDAZIONE";
      }
    };
    const decodePolizzaStatusType = function (status) {
      switch (status) {
        case "finished":
          return "success";
        case "broken rules":
          return "danger";
        case "awaiting UW docs":
          return "warning";
        case "awaiting SQL UW":
          return "info";
      }
    };

    const goToDocumenti = function () {
      router.push("/documenti/" + props.polizza.policy_number);
    };

    const goToUploadDocs = function () {
      router.push("/upload-esami/" + props.polizza.policy_number);
    };

    const goToFabbisogni = function () {
      router.push("/fabbisogni/" + props.polizza.policy_number);
    };

    const goToCoperture = function () {
      router.push("/coperture/" + props.polizza.policy_number);
    };

    const goToBeneficiari = function () {
      router.push("/beneficiari/" + props.polizza.policy_number);
    };

    const goToMetodoPagamento = function () {
      router.push("/metodopagamento/" + props.polizza.policy_number);
    };

    const goToAgente = function () {
      router.push("/agente");
    };

    watch(props.prodotto, () => {}, { deep: true });

    const goToPortalePagamenti = async function () {
      let resp = await apiCall({
        url: "/private/pagamenti/portale",
        data: {},
        method: "GET",
      });
      if (!resp.err) {
        window.open(resp.url, "_blank");
      } else {
        console.dir(resp.err);
      }
    };

    return {
      tariffe,
      tariffa,
      statuspolizza,
      isLoading,
      openDiagnosi,
      decodePolizzaStatusType,
      decodePolizzaStatus,
      decodePolizzaDesc,
      decodeDiagnosiType,
      formatDate,
      toCurrency,
      goToDocumenti,
      goToUploadDocs,
      goToFabbisogni,
      goToCoperture,
      goToBeneficiari,
      goToMetodoPagamento,
      goToAgente,
      errorPolizza,
      goToPortalePagamenti,
    };
  },
};
</script>
